/* Green variables */
$theme-color: #5BB66F;
$text-color: #3F4650;
$text-color-secondary: #545E6C;
$text-grey: #97AAC3;
$divider: #e8e8e8;
$lighter-grey: #ccc;
$darker-grey: #666;
$smoky-white: #f5f5f5;
$heart: #fb866a;
